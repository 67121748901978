import React, {useEffect} from "react";
import Bluedart from "../Assets/SeoRoutes/Bluedart.png"
import bannerimg from "../Assets/SeoRoutes/bannerimg.png"
import Dtdc from "../Assets/SeoRoutes/Dtdc.png"
import Delhivery from "../Assets/SeoRoutes/delhivery.png"
import Xpressbeas from "../Assets/SeoRoutes/xpressbeas.png"
import ecomexpress from "../Assets/SeoRoutes/ecom-express.png"
import amazon from "../Assets/SeoRoutes/amazon.png"
import sidebanner from "../Assets/BlogPhotos/sidebanner.png"
import Rating from "../Assets/Homepage/Rating.png"
import deliverytruck from "../Assets/Homepage/delivery-truck.png"
import Realtime from "../Assets/Homepage/Real-time.png"
import { Link, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";


function SeoRoutes() {
    const { city } = useParams()
    
    const navigate = useNavigate();

  
    const cityName = [
        { id: 1, city: 'Delhi', displayName: 'Best Courier Service in Delhi' },
        { id: 2, city: 'Jaipur', displayName: 'Best Courier Service in Jaipur' },
        { id: 3, city: 'Bangalore', displayName: 'Best Courier Service in Bangalore' },
        { id: 4, city: 'Mumbai', displayName: 'Best Courier Service in Mumbai' },
        { id: 5, city: 'Karnataka', displayName: 'Best Courier Service in Karnataka' },
        { id: 6, city: 'Lucknow', displayName: 'Best Courier Service in Lucknow' },
        { id: 7, city: 'Chennai', displayName: 'Best Courier Service in Chennai' },
        { id: 8, city: 'Hyderabad', displayName: 'Best Courier Service in Hyderabad' },
        { id: 9, city: 'Pune', displayName: 'Best Courier Service in Pune' },
        { id: 10, city: 'Ahmedabad', displayName: 'Best Courier Service in Ahmedabad' },
        { id: 11, city: 'Surat', displayName: 'Best Courier Service in Surat' },
        { id: 12, city: 'Kolkata', displayName: 'Best Courier Service in Kolkata' },
        { id: 13, city: 'Patna', displayName: 'Best Courier Service in Patna' },
        { id: 14, city: 'Nagpur', displayName: 'Best Courier Service in Nagpur' },
        { id: 15, city: 'Kanpur', displayName: 'Best Courier Service in Kanpur' },
        { id: 16, city: 'Agra', displayName: 'Best Courier Service in Agra' },
        { id: 17, city: 'Chandigarh', displayName: 'Best Courier Service in Chandigarh' },
        { id: 18, city: 'Dehradun', displayName: 'Best Courier Service in Dehradun' },
        { id: 19, city: 'Gurgaon', displayName: 'Best Courier Service in Gurgaon' },
        { id: 20, city: 'Indore', displayName: 'Best Courier Service in Indore' },
        { id: 21, city: 'Coimbatore', displayName: 'Best Courier Service in Coimbatore' },
        { id: 22, city: 'Vadodara', displayName: 'Best Courier Service in Vadodara' },
        { id: 23, city: 'Vijayawada', displayName: 'Best Courier Service in Vijayawada' },
        { id: 24, city: 'Bhubaneswar', displayName: 'Best Courier Service in Bhubaneswar' },
        { id: 25, city: 'Jaipur', displayName: 'Best Courier Service in Jaipur' },
        { id: 26, city: 'Mangalore', displayName: 'Best Courier Service in Mangalore' },
        { id: 27, city: 'Jodhpur', displayName: 'Best Courier Service in Jodhpur' },
        { id: 28, city: 'Ranchi', displayName: 'Best Courier Service in Ranchi' },
        { id: 29, city: 'Shimla', displayName: 'Best Courier Service in Shimla' },
        { id: 30, city: 'Trivandrum', displayName: 'Best Courier Service in Trivandrum' },
        { id: 31, city: 'Kochi', displayName: 'Best Courier Service in Kochi' },
        { id: 32, city: 'Tiruchirapalli', displayName: 'Best Courier Service in Tiruchirapalli' },
        { id: 33, city: 'Nashik', displayName: 'Best Courier Service in Nashik' },
        { id: 34, city: 'Gwalior', displayName: 'Best Courier Service in Gwalior' },
        { id: 35, city: 'Meerut', displayName: 'Best Courier Service in Meerut' },
        { id: 36, city: 'Udaipur', displayName: 'Best Courier Service in Udaipur' },
        { id: 37, city: 'Tirupati', displayName: 'Best Courier Service in Tirupati' },
        { id: 38, city: 'Bikaner', displayName: 'Best Courier Service in Bikaner' },
        { id: 39, city: 'Mysore', displayName: 'Best Courier Service in Mysore' },
        { id: 40, city: 'Aurangabad', displayName: 'Best Courier Service in Aurangabad' },
        { id: 41, city: 'Rajkot', displayName: 'Best Courier Service in Rajkot' },
        { id: 42, city: 'Jamshedpur', displayName: 'Best Courier Service in Jamshedpur' },
        { id: 43, city: 'Faridabad', displayName: 'Best Courier Service in Faridabad' },
        { id: 44, city: 'Kakinada', displayName: 'Best Courier Service in Kakinada' },
        { id: 45, city: 'Durgapur', displayName: 'Best Courier Service in Durgapur' },
        { id: 46, city: 'Siliguri', displayName: 'Best Courier Service in Siliguri' },
        { id: 47, city: 'Haldwani', displayName: 'Best Courier Service in Haldwani' },
        { id: 48, city: 'Bilaspur', displayName: 'Best Courier Service in Bilaspur' },
        { id: 49, city: 'Cuttack', displayName: 'Best Courier Service in Cuttack' },
        { id: 50, city: 'Jabalpur', displayName: 'Best Courier Service in Jabalpur' },
    ];

    const validCity = cityName.find(cityName => cityName.city.toLowerCase() === city.toLowerCase());
    

    useEffect(() => {
        if (!validCity || !city) {
            navigate("/"); 
        } else {
            window.scrollTo({ top: 0 });
        }
    }, [city, validCity, navigate]);


    const handleCityClick = (city) => {
     const path = `/courier-services-in-${city}`
        navigate(path);
    }



    const getRandomCities = (numcities) => {
        return cityName
            .sort(() => Math.random() - 0.5) 
            .slice(0, numcities); 
    }
   

    const randomCities = getRandomCities(15);


    const blogs = [
        {
            id: 1,
            imageUrl: Delhivery,
            title: 'Delhivery',
            content: "Delhivery provides fast courier services and dependable delivery solutions, ensuring your shipments reach their destination on time, every time, through a vast network of locations. Known for its efficiency, Delhivery is among the best courier companies offering domestic courier services tailored to meet both personal and business needs.",
            contenttwo: "Their courier service pickup from home adds convenience, while their one day courier service is perfect for urgent deliveries. As a technology-focused company, Delhivery provides businesses with innovative logistical solutions and seamless tracking, making them the go-to choice for reliable and efficient delivery courier companies.",
            point: "Delhivery offers",
            Subpoint1: "Extensive Network",
            subpoint2: "Advanced Technology",
            subpoint3: "Customer Focus"
        },
        {
            id: 2,
            imageUrl: Dtdc,
            title: 'Dtdc',
            content: "DTDC stands out with its extensive domestic courier services and worldwide shipping options, making it one of the best courier companies in the industry. Known for its punctuality and dependable delivery, DTDC ensures your packages arrive safely and on time.",
            contenttwo: "Whether you’re looking for fast courier services or courier service pickup from home, DTDC offers a variety of solutions, including one day courier service and comprehensive logistics management. With a strong focus on quality, DTDC remains a trusted name among delivery courier companies, dedicated to meeting a wide range of shipping needs.",
            point: "DTDC offers",
            Subpoint1: "International Shipping",
            subpoint2: "Diverse Services",
            subpoint3: "Quality Assurance"
        },
        {
            id: 3,
            imageUrl: Bluedart,
            title: 'Blue Dart',
            content: "Blue Dart is a leader in the express logistics industry, offering reliable courier services across India and internationally. Recognized for its punctuality and dependable delivery, DTDC guarantees that your packages arrive at their destination promptly and safely.",
            contenttwo: "With a variety of services ranging from logistics management to expedited delivery, DTDC is dedicated to fulfilling a wide range of shipping requirements. They are well-known in the courier sector thanks to their emphasis on providing high-quality service.",
            point: "Blue Dart offers",
            Subpoint1: "Reliable Service",
            subpoint2: "Advanced Tracking",
            subpoint3: "National and International Reach",
        }
        ,
        {
            id: 4,
            imageUrl: Xpressbeas,
            title: 'XpressBeas',
            content: "Blue Dart is a leader in the express logistics industry, offering reliable domestic courier services across India and internationally. Known for its punctuality and dependable delivery, Blue Dart ensures that your packages reach their destination promptly and safely.",
            contenttwo: "Whether you’re searching for fast courier services near me or courier service pickup from home, Blue Dart provides a range of services, including one day courier service and comprehensive logistics management. As one of the best courier companies, Blue Dart is dedicated to fulfilling a wide range of shipping needs, earning its reputation in the courier sector through its commitment to high-quality service.",
            point: "XpressBeas offers",
            Subpoint1: "E-Commerce Focus",  
            subpoint2: "Swift Delivery",
            subpoint3: "Excellent Service",
        },
        {
            id: 5,
            imageUrl: ecomexpress,
            title: 'Ecom Express',
            content: "Reliability and e-commerce business-specific delivery services are the hallmarks of Ecom Express. With their domestic courier services and vast network, they are a leading option for fast courier services and one day courier service, ensuring prompt and efficient parcel delivery.",
            contenttwo: " Ecom Express is dedicated to handling packages with care, offering real-time tracking as part of their customer-centric approach. Whether you need courier service pickup from home or seamless shipping, Ecom Express guarantees a smooth experience from collection to final delivery, making them one of the best courier companies for dependable service.",
            point: "Ecom Express offers",
            Subpoint1: "E-Commerce Specialization",
            subpoint2: "Timely Deliveries",
            subpoint3: "Real-Time Tracking",
        }
        ,
        {
            id: 6,
            imageUrl: amazon,
            title: 'Amazon Shipping',
            content: "Amazon Shipping provides fast courier services and dependable delivery options specifically designed to meet the needs of e-commerce businesses. Leveraging Amazon's strong infrastructure, they ensure the prompt and safe delivery of your items, making them a reliable choice for domestic courier services.",
            contenttwo: "Both customers and businesses benefit from courier service pickup from home, user-friendly tracking features, and first-rate customer support. With their dedication to reliability and efficiency, Amazon Shipping stands out as one of the best courier companies, positioned at the forefront of e-commerce delivery solutions.",
            point: "Amazon Shipping offers",
            Subpoint1: "Efficient Delivery",
            subpoint2: "User-Friendly Tracking",
            subpoint3: "Customer Support",
            date: 'April 2, 2024',
            author: 'Bhuvan Ralhan'
        }
    ]
     
    return (
        <React.Fragment>
            <div className="flex mt-20 flex-col ">
                <Helmet>
                    <title>{`Best Courier Services in ${city}`}</title>
                    <meta name="description" content= {`Explore the best courier services available in ${city} through Couriero. Compare rates and find reliable delivery options tailored to your needs.`}></meta>
                    <link rel="canonical" href="courier-services-in-:city"/>
                </Helmet>
                <div className="flex bg-gradient-to-r from-purple-50 to-blue-200    flex-row">
                    <div className="flex py-10 gap-3 md:w-1/2 lg:w-1/2 flex-col px-10">
                        <h1 className="flex pl-1 text-slate-600 text-clip text-lg">Searching for Best Courier Platform?</h1>
                        <h2 className="lg:text-[3.6rem] md:text-[3.6rem] text-[2.2rem] leading-[2.6rem] font-semibold  tracking-tighter lg:leading-[4rem]  md:leading-[4rem]   bg-gradient-to-r bg-clip-text from-blue-300 to-orange-300 text-transparent">Top courier and parcel <span className="font-semibold text-indigo-900">
                            Services in {city}</span>
                        </h2>
                        <h2 className=" text-sm text-justify pt-2">Avoid the headaches of trying to find the best courier company for your needs! With Couriero, you can rely on our domestic courier services to meet all your demands. Whether you need courier service pickup from home or are searching for fast courier services near me, we provide a comprehensive list of the top delivery courier companies in your area. </h2>
                        <div>
                            <Link to="/">
                                <button class="relative lg:text-base md:text-base text-sm  mt-4 pr-11 pl-7 hover:shadow-md  lg:py-3 py-2  hover:duration-300  bg-blue-900  hover:text-blue-900 hover:border hover:border-blue-900 font-semibold hover:bg-white  text-white lg:rounded-xl md:rounded-xl rounded-r-full rounded-tl-full  group">
                                    Sign up for free
                                    <i class="fa-solid fa-arrow-right absolute pl-2 pt-1 transition-transform duration-300 ease-in-out group-hover:translate-x-1"></i>
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className="flex w-1/2">
                        <div className="flex justify-center items-center">
                            <img alt="Shipping partner banner" className=" mb-9 w-10/12 h-11/12" src={bannerimg} />
                        </div>
                    </div>
                </div>
                <div className="flex flex-row px-7 w-full">
                    <div className='lg:grid   w-full lg:mx-5 md:mx-5  h-full  lg:grid-flow-row grid-cols-2 gap-4  lg:w-8/12'>
                        {blogs.map(blog => (
                            <div key={blog.id} className='flex mt-4  px-5 py-8 pb-0 border hover:duration-200 hover:shadow-lg  justify-between shadow-gray-400 flex-col ml-2 '>

                                <img src={blog.imageUrl}  alt='blog' className='w-24 ml-1 flex' />

                                <div className='flex px-1  flex-col '>
                                    <h3 className='lg:text-base cursor-pointer hover:underline text-justify lg:text-left font-semibold py-4'>{blog.title}</h3>
                                    <p className='lg:text-[0.8rem] text-gray-600   text-sm mt-1 text-justify lg:text-start'>{blog.content}</p>
                                    <p className='lg:text-[0.8rem] text-gray-600 text-sm mt-1 text-justify lg:text-start'>{blog.contenttwo}</p>
                                </div>
                                <div className="flex flex-col px-1 mt-7">
                                    <h3 className="text-blue-900">{blog.point}</h3>
                                    <div className="flex flex-col mt-3">
                                        <h3 className="text-gray-700 text-sm">• {blog.Subpoint1}</h3>
                                        <h3 className="text-gray-700 text-sm">• {blog.subpoint2}</h3>
                                        <h3 className="text-gray-700 text-sm">• {blog.subpoint3}</h3>
                                    </div>
                                </div>
                                <div className='flex px-1 text-sm pt-2 mb-8 pb-2 flex-row w-full justify-end'>
                                    <Link to="/">
                                        <button className="flex px-4 py-1 bg-blue-900 text-white rounded-lg">Try Now</button>
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="lg:flex md:flex hidden flex-col w-4/12">
                        <div className='flex px-4 pt-5 '>
                            <img className='shadow-xl rounded-2xl' alt='couriero-banner' src={sidebanner}></img>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="flex  my-16 px-10 flex-col">
                        <h1 className="flex lg:pl-3 md:pl-3 pl-2 text-indigo-900 mb-5 lg:text-[2.6rem] md:text-[2.6rem] text-[2rem] font-bold">Still Thinking? Choose Couriero</h1>
                        <div className="flex justify-center items-center md:flex-row flex-col lg:flex-row w-full">
                            <div className="flex px-5 lg:text-left md:text-left text-justify lg:pt-0 md:pt-0 pt-4 text-gray-500  text-sm pl-3 md:w-1/2 w-full lg:w-1/2">
                            If you're struggling to choose the best courier company for your needs, you’re not alone. Some services specialize in fast courier services but come with high shipping fees, while others may offer lower prices but don’t deliver to your location. How can you enjoy the benefits of both without the hassle? The solution is simple: partner with a third-party logistics provider like Couriero. For eCommerce businesses, Couriero offers a one day courier service and a comprehensive delivery solution. Serving over 24,000 pin codes across India, Couriero connects with more than 25 delivery courier companies, allowing you to manage multiple courier partners seamlessly through our platform. Experience the convenience of our service with access to a unique range of products and cutting-edge technology.</div>
                            <div className="flex lg:mr-0 mr-12 gap-5 lg:w-1/2 w-full md:w-1/2 justify-center items-center  py-4 lg:py-0  flex-col ">
                                <div className="bg-green-50 lg:w-2/5  cursor-pointer group py-2 px-3  rounded-lg  flex
                        //  flex-row ">
                                    <div className="flex w-3/4 flex-col">
                                        <h3 className="flex font-semibold text-2xl">30,000+</h3>
                                        <h3 className="flex text-xs">Satiesfied Sellers</h3>
                                    </div>
                                    <div className="flex w-1/4 justify-center items-center" >
                                        <img src={Rating} alt="Rating" className="w-16 lg:w-12 md:w-12  h-12 transition-transform duration-300 ease-in-out group-hover:translate-x-2" />
                                    </div>
                                </div>
                                <div className="bg-blue-50 lg:ml-56 ml-28 group py-2 px-3 cursor-pointer  rounded-lg lg:w-2/5 flex flex-row ">
                                    <div className="flex w-3/4 flex-col">
                                        <h3 className="flex font-semibold text-2xl">30,000+</h3>
                                        <h3 className="flex text-xs">Daily Shipments</h3>
                                    </div>
                                    <div className="flex w-1/4 justify-center items-center" >
                                        <img src={deliverytruck} alt="delivery-truck" className="w-16 lg:w-12 md:w-12 h-12 transition-transform duration-300 ease-in-out group-hover:translate-x-2" />
                                    </div>
                                </div>
                                <div className="bg-yellow-50  py-2 px-3 group cursor-pointer  rounded-lg lg:w-2/5 flex flex-row ">
                                    <div className="flex w-3/4 flex-col">
                                        <h3 className="flex font-semibold text-2xl">93.3%</h3>
                                        <h3 className="flex text-xs">On Time Delivery</h3>
                                    </div>
                                    <div className="flex w-1/4 justify-center items-center" >
                                        <img src={Realtime} alt="Real-time" className="w-16 lg:w-12 md:w-12 h-12 transition-transform duration-300 ease-in-out group-hover:translate-x-2" />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="flex mt-7 py-16  bg-gradient-to-b from-blue-100 to-blue-600 text-white  flex-col ">
                        <h3 className="flex lg:px-20 text-blue-900 md:px-20 px-5 mb-8 md:text-[2.2rem] text-[1.5rem] lg:text-[2.2rem] font-bold">Best courier services in popular cities</h3>
                        <div className="w-full lg:grid lg:grid-cols-3 lg:px-20 md:px-20 px-5   h-full flex gap-5 flex-wrap">
                            {randomCities.map(city => (
                                <div key={city.id} onClick={() => handleCityClick(city.city)} className="flex w-full hover:cursor-pointer  hover:bg-blue-900 justify-between flex-row  items-center  shadow-md p-4  bg-blue-800 rounded-lg">
                                    <h3 className="text-white flex  text-base font-semibold">{city.displayName}</h3>
                                    <i className="fa-solid flex   fa-arrow-up-right-from-square"></i>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="flex flex-row w-full ">
                    <h3 className="flex px-5 text-gray-600 lg:text-left text-justify md:text-left text-sm py-14">
                    Couriero stands out with its exceptional features designed to enhance your shipping experience. Enjoy top-notch customer support that’s always ready to assist, and benefit from real-time tracking updates to keep you informed every step of the way. With our user-friendly shipping rates calculator, you can easily estimate costs before sending your parcel. Plus, with over 15 delivery courier companies partnered with Couriero, you have access to a broad network ensuring reliable and efficient fast courier services. Whether you need courier service pickup from home or a one day courier service, choose Couriero for a seamless shipping solution tailored to your needs.</h3>
                </div>
                <div className="flex lg:flex-row flex-col bg-gradient-to-r rounded-3xl mb-11 py-14 from-white via-blue-200 to-blue-300 ">
                    <div className="flex px-10 lg:w-1/2 md:w-1/2 w-full flex-col">
                        <h3 className="flex  font-bold lg:w-3/4 lg:text-left md:text-left text-center md:leading-[3rem] lg:leading-[3rem] bg-clip-text bg-gradient-to-r from-blue-600 to-purple-700 text-transparent text-3xl md:text-[3rem]  lg:text-[3.1rem]">
                            Get started with Couriero
                        </h3>
                        <h3 className="text-base flex justify-center items-center lg:justify-start  lg:items-start
                        md:justify-start md:items-start pt-2 pl-1 font-semibold text-indigo-900">We make shipping fast and reliable</h3>
                    </div>
                    <div className="flex lg:w-1/2   justify-end items-end">

                        <div className='flex lg:mr-20 md:mr-20 mt-5  mb-3 flex-row  w-full text-center justify-center md:justify-end lg:justify-end items-center'>
                            <a href='http://app.couriero.in/login' target='_blank' rel="noopener noreferrer">
                                <button className='flex py-3 text-lg px-10 bg-blue-800 text-white font-bold rounded-3xl shadow-md rounded-bl-none hover:border duration-300  hover:font-extrabold hover:border-blue-800 hover:bg-white hover:text-blue-900'>
                                    Sign-up now
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default SeoRoutes;